<template>
    <div>
        <el-card class="box-card">
            <el-row :gutter="30">
                <el-col :span="8">
                    <!--搜索-->
                    <el-input placeholder="请输入职务称谓" v-model="queryInfo.name" clearable @clear="GetIdentitiesList"> 
                        <el-button slot="append" icon="el-icon-search" @click="GetIdentitiesList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addDialogVisible = true">添加职务</el-button>
                </el-col>
            </el-row>
            <tree-table class="TT_IdentitiesList" 
            :data="IdentitiesList"  
            :columns="TreeTableColumns" :show-row-hover="false"   
            border show-index index-text="#" 
              :expand-type="false" :selection-type="false" treeType v-loading="Loading">
                <template slot="LevelTemplate" slot-scope="scope">
                    <el-tag v-if="scope.row.parentID === 0">一级</el-tag>
                    <el-tag type="success" v-else-if="scope.row.parentID !== 0">二级</el-tag>
                </template>
                <template slot="OptionTemplate" slot-scope="scope">
                    <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
                        <el-button type="primary" icon="el-icon-edit" @click="ShowEditDialog(scope.row)"></el-button>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="津贴" placement="top" :enterable="false">
                        <el-button type="warning" icon="el-icon-setting" v-if="scope.row.parentID !== 0" @click="ShowTreeDialog(scope.row)"></el-button>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                        <el-button type="danger" icon="el-icon-delete" @click="DeleteIdentityByID(scope.row.id)"></el-button>
                    </el-tooltip>
                </template>
            </tree-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.start"
                :page-sizes="[1,2,5,10]"
                :page-size="queryInfo.count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="identitietotal">
            </el-pagination>
        </el-card>
        <el-dialog title="添加职务" :visible.sync="addDialogVisible" width="40%" @close="AddDialogClosed" :close-on-click-modal="false">
            <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="120px" v-loading="AdLoading">
                <el-form-item label="称谓" prop="Title">
                    <el-input v-model="addForm.Title"></el-input>
                </el-form-item>
                <el-form-item label="基本工资(￥)" prop="BasicWage">
                    <el-input-number style="width :90%" v-model="addForm.BasicWage" :precision="2" :step="0.1" :min="0" :max="5000"></el-input-number>
                </el-form-item>
                <el-form-item label="课时费(￥)" prop="TuitionFee">
                    <el-input-number style="width :90%" v-model="addForm.TuitionFee" :precision="2" :step="0.1" :min="0" :max="100"></el-input-number>
                </el-form-item>
                <el-form-item label="选择父类职务：">
                    <el-select style="display:table" width="30" v-model="ParentIdentityIDvalue" placeholder="请选择父类职务：" >
                        <el-option
                        v-for="item in IdentitiesList"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id">
                            <span style="float: left">{{ item.title }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="AddDialogClosed">取 消</el-button>
                <el-button type="primary" @click="Addidentity" :disabled="AdLoading">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="编辑职务" :visible.sync="editDialogVisible" width="40%" @close="EditDialogClosed" :close-on-click-modal="false">
           <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="120px" v-loading="EdLoading">
                <el-form-item label="称谓" prop="Title">
                    <el-input v-model="editForm.title"></el-input>
                </el-form-item>
                <el-form-item label="基本工资(￥)" prop="BasicWage">
                    <el-input-number style="width :90%" v-model="editForm.basicWage" :precision="2" :step="0.1" :min="0" :max="5000"></el-input-number>
                </el-form-item>
                <el-form-item label="课时费(￥)" prop="TuitionFee">
                    <el-input-number style="width :90%" v-model="editForm.tuitionFee" :precision="2" :step="0.1" :min="0" :max="100"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="EditDialogClosed">取 消</el-button>
                <el-button type="primary" @click="ModifyIdentity" :disabled="EdLoading">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="分配补助" :visible.sync="setAllowanceDialogVisible" width="30%" @close="setAllowanceDialogClosed" :close-on-click-modal="false">
            <el-tree 
            :data="allowancetypelist" 
            :props="allowancetypeProps" 
            show-checkbox node-key="id" 
            default-expand-all
            ref="tree"
            :default-checked-keys="defSelectedKeys"
            v-loading="AlLoading">
            </el-tree>
            <span slot="footer" class="dialog-footer">
                <el-button @click="setAllowanceDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="ModifyIdentityAllowance" :disabled="AlLoading">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import {  InputNumber,Tree} from 'element-ui';
import TreeTable from 'vue-table-with-tree-grid'
Vue.component('tree-table',TreeTable)
Vue.use(InputNumber)
Vue.use(Tree)
export default {
    data(){
        return{
            queryInfo:{
                name:'',
                start:1,
                count:10
            },
            ParentIdentityIDvalue:'',
            identitietotal:0,
            IdentitiesList:[],
            TreeTableColumns:[
                {
                    label:'名称',
                    prop:'title'
                },
                {
                    label:'基本薪资(￥)',
                    prop:'basicWage'
                },
                {
                    label:'课时费(￥)',
                    prop:'tuitionFee',
                },
                {
                    label:'等级',
                    type:'template',
                    template:'LevelTemplate'
                },
                {
                    label:'操作',
                    type:'template',
                    template:'OptionTemplate'
                }
            ],
            addDialogVisible:false,
            addFormRules:{
                Title: [
                    { required: true, message: '请输入称谓', trigger: 'blur' }
                ]
            },
            addForm:{
                Title:'',
                BasicWage:0.00,
                TuitionFee:0.00,
                ParentID:0
            },
            editDialogVisible:false,
            editForm:{
                id:0,
                title:'',
                basicWage:0.00,
                tuitionFee:0.00,
                allowanceInfos:[]
            },
            editFormRules:{
                title: [
                    { required: true, message: '请输入称谓', trigger: 'blur' }
                ]
            },
            allowanceTypeDic:[],
            setAllowanceDialogVisible:false,
            //默认选中的id
            defSelectedKeys:[],
            allowancetypelist:[],
            allowancetypeProps:{
                children:'children',
                label:'type'
            },
            modifyidentityID:{
                identityID:0,
                allowanceIDs:[]
            },
            Loading:false,
            AdLoading:false,
            EdLoading:false,
            AlLoading:false
        }
    },
    created(){
        this.GetIdentitiesList()
    },
    methods:{
        async GetIdentitiesList(){
            this.Loading = true
            const {data:res} = await this.$http.get('Identity/v1/IdentitiesQuery',{params: this.queryInfo})
            this.Loading = false
            if(res.code !== 1){
                return this.$message.error('获取职务列表失败');
            }
            this.IdentitiesList = res.data.identities
            this.identitietotal = res.data.total
        },
        async GetAllowanceTypeDic(){
            this.AlLoading = true
            let _this =this
            const {data:res} = await this.$http.get('AllowanceType/v1/AllowanceTypeDic')
            if(res.code !== 1){
                _this.AlLoading = false
                return _this.$message.error('获取津贴字典失败');
            }
            // this.allowanceTypeDic = res.data
            this.AlLoading = false
            this.allowancetypelist = res.data
            // console.log(this.allowancetypelist)
        },
        handleSizeChange(count){
            this.queryInfo.count = count
            this.GetIdentitiesList()
        },
        handleCurrentChange(start){
            this.queryInfo.start = start
            this.GetIdentitiesList()
        },
        AddDialogClosed(){
            this.ParentIdentityIDvalue = ''
            this.$refs.addFormRef.resetFields()
            this.addDialogVisible = false
        },
        async Addidentity(){
            this.$refs.addFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            if(this.ParentIdentityIDvalue !== ''){
                this.addForm.ParentID = parseInt(this.ParentIdentityIDvalue)
            }
            this.AdLoading = true
            let _this =this
            await this.$http.post('Identity/v1/Identity',this.addForm).
            then(function (response) {
                if(response.data.code != 1)
                {
                    _this.AdLoading = false
                    return _this.$message.error('添加职务失败')
                }
            }).catch(function (error) {
                _this.AdLoading = false
                return _this.$message.error(error)
            });   
            this.AdLoading = false
            this.validret = false
            this.addDialogVisible = false
            this.GetIdentitiesList()
            this.$message.success('添加职务成功')
        },
        EditDialogClosed(){
            this.$refs.editFormRef.resetFields()
            this.editDialogVisible = false
        },
        async ModifyIdentity(){
            this.$refs.editFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            } 
            this.EdLoading= true
            let _this = this
            await this.$http.put('Identity/v1/Identity',this.editForm).
            then(function (response) {
                if(response.data.code != 1)
                {
                    _this.EdLoading = false
                    return _this.$message.error('修改职务失败')
                }
            }).catch(function (error) {
                _this.EdLoading = false
                return _this.$message.error(error)
            });
            this.EdLoading= false
            this.validret = false
            this.editDialogVisible = false
            this.GetIdentitiesList()
            this.$message.success('修改职务成功')
        },
        ShowEditDialog(identityinfo){
            this.editForm = identityinfo
            this.editDialogVisible = true
        },
        async DeleteIdentityByID(id){
            var that = this;
            const confirmResult = await that.$confirm('此操作将永远删除该职务, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return that.$message.info('已取消删除')
            }
            that.Loading = true
            await that.$http.delete('Identity/v1/Identity',{params: {id : id}}).
            then(function (response) {
                if(response.data.code != 1)
                {
                    that.$message.info('删除失败')
                }
                else
                {
                    that.$message.success('删除成功')
                }
            }).catch(function (error) {
                that.Loading = false
                return that.$message.info(error)
            });
            that.Loading = false
            that.GetIdentitiesList()
        },
        setAllowanceDialogClosed(){
            this.defSelectedKeys.length = 0
        },
        ShowTreeDialog(identityinfo){
            // console.log(identityinfo)
            let that = this
            that.GetAllowanceTypeDic()
            if(identityinfo.allowanceInfos.length != 0)
            {
                identityinfo.allowanceInfos.forEach(function(item){
                    that.defSelectedKeys.push(item.allowanceID)
                });
            }
            that.modifyidentityID.identityID = identityinfo.id
            that.setAllowanceDialogVisible = true
        },
        async ModifyIdentityAllowance(){
            let that = this
            that.modifyidentityID.allowanceIDs = that.$refs.tree.getCheckedKeys()
            that.AlLoading = true
            await that.$http.put('Allowance/v1/AllowanceByIdentify',that.modifyidentityID).
            then(function (response) {
                if(response.data.code != 1)
                {
                    that.$message.info('修改津贴失败')
                }
                else
                {
                    that.$message.success('修改津贴成功')
                }
            }).catch(function (error) {
                 that.AlLoading = false
                return that.$message.info(error)
            });
             that.AlLoading = false
            that.GetIdentitiesList()
            that.setAllowanceDialogVisible = false
        },
        async DeleteAllowanceByIdentity(list,info)
        {
            let that = this
            await that.$http.delete('Allowance/v1/AllownaceByIdentityID',{params: {allowanceID : info.allowanceID , idetityID:info.identityID}}).
            then(function (response) {
                if(response.data.code != 1)
                {
                    that.$message.info('删除失败')
                }
                else
                {
                    that.$message.success('删除成功')
                }
            }).catch(function (error) {
                return that.$message.info(error)
            });
            const item = list.indexOf(info)
            list.splice(item, 1)
            
        }
    }
}
</script>

<style scoped>
.TT_IdentitiesList{
    margin-top: 15px;
}
</style>